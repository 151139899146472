import React from 'react'
import { Link } from 'gatsby'
import { removeFormValues } from "../services/form"
import { sessionKeys } from "../services/const"
import Seo from '../components/seo'

class JoinFormComplete extends React.Component {
  componentDidMount = () => {
    removeFormValues(sessionKeys.joinForm);
  }
  render = () => {
    return (
      <>
        <Seo title="入会申込み" />
        <div id="pageTitle">
          <h1>
            入会申込み
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/member">入会のご案内</Link></li>
            <li>入会申込み</li>
          </ul>
        </div>
        <div id="main">
          <div className="contents">
            <section className="contents_block">
              <h2 className="header01">入会申し込みが完了しました</h2>
              <div className="inner">
                <p className="mb100">入会の申し込みが完了しました。<br />
                  受付が完了後、協会よりご連絡いたします。</p>
              </div>
            </section>
          </div>
        </div>
      </>
    )
  }
}

export default JoinFormComplete